<template>
    <div
        class="d-flex flex-column align-items-center justify-content-center p-2"
        style="height: 65vh"
    >
        <img
            class="d-inline-block mb-1 mw-100"
            width="400"
            src="@/assets/imgs/404.svg"
            alt="500 serve error"
        />
        <h1 class="h2 mb-4 text-center">This Page is not found</h1>
        <router-link to="/" class="btn btn-brand"
            >Back to Home Page</router-link
        >
    </div>
</template>

<script>
export default {
    name: "NotFound",
};
</script>
